import { Assets } from "../service/types";

import Uppy, { type UppyFile, type FailedUppyFile } from '@uppy/core';
import AwsS3 from '@uppy/aws-s3';
import Dashboard from '@uppy/dashboard';

import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';

// const sources = [
//   "local",
//   "camera",
//   "url",
//   "image_search",
// ];

type UppyFileImage = UppyFile

export type UppyUploadError = FailedUppyFile<Record<string, unknown>, Record<string, unknown>>[]
export type UppyUploadResult = { event: string; info?: UppyFileImage[] };

export const handleUploadClick = ({
  storyId = "",
  callback = (
    error: UppyUploadError,
    result: UppyUploadResult,
  ) => {},
}) => {
  const uppy = new Uppy({
    restrictions: {
      maxFileSize: 20 * 1000000,
      allowedFileTypes: ["image/*"],
      maxNumberOfFiles: 25
    }
  }).use(Dashboard, { 
    closeModalOnClickOutside: true
  }).use(AwsS3, {
    companionUrl: `${process.env.REACT_APP_S3_COMPANION_URL}/api/v1/stories/${storyId}/slideshow/`,
    companionHeaders: {
      "sf-slug": storyId,
    }
  });

  // @ts-ignore
  uppy.getPlugin('Dashboard').openModal()

  uppy.on('complete', (result) => {
    callback(result.failed, {event: 'complete', info: result.successful});
  });
};

export const normalizeUppyAssets = (assets: UppyFileImage[] = []): Assets => {
  return assets.reduce((assets: Assets, asset: UppyFileImage) => {    
    if(asset.meta.key) {
      return {
        ...assets,
        [asset.id]: {
          assetId: asset.meta.key as string,
          publicId: asset.meta.key as string,
          format: asset.extension,
          bytes: asset.size || undefined,
          resourceType: asset.type
        }
      }
    } else {
      return assets
    }
  }, {} as Assets);
};