import React, { useState } from "react";
import { v4 } from "uuid";
import { ReactSortable } from "react-sortablejs";
import Skeleton from "react-loading-skeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faTrash,
  faFileUpload,
} from "@fortawesome/free-solid-svg-icons";
import styled from "@emotion/styled";
import { rem } from "polished";
import {
  TimelineBoxGrid,
  TimelineBoxList,
  TimelineBoxBorder,
} from "./TimelineBox";
import { Title } from "../Title";
import { EmptyPicture, Picture, RemoveButton } from "./Picture";
import { Upload } from "./Upload";
import {
  UppyUploadError,
  UppyUploadResult,
  handleUploadClick,
} from "../../hooks/Uppy";
import { isTouchDevice } from "../../helpers/isTouchDevice";

import { ViewSwitcher } from "./ViewSwitcher";

import { GhostButton } from "../Button";
import {
  ItemMetric,
  TimelineGapMetrics,
  useExtendedTimelineMetrics,
} from "../../helpers/getTimelineGapMetrics";
import { ValidityDisplay } from "./ValidityDisplay";
import { css } from "@emotion/react";

import { useTimelineContext } from "../../hooks/useTimelineContext";
import Sortable from "sortablejs";
import { useGetDurationBetweenContext } from "../../hooks/useGetDurationBetweenContext";
import { usePlayerContext } from "../../hooks/usePlayerContext";
import { Assets, TimelineListType } from "../../service/types";

type ChildrenProps = {
  projectId: string;
  isLoading?: boolean;
  list: TimelineListType;
  updateTimeline: React.Dispatch<React.SetStateAction<TimelineListType>>;
  sectionsCount: number;
  activeIndex: number;
  assets: Assets;
  addPictures: (
    error: UppyUploadError,
    result: UppyUploadResult,
    index?: number,
  ) => void;
  submittedAt?: string;
  setSubmittedAt?: (date: string) => void;
};

const defaultProps = {
  Title,
  TimelineBox: TimelineBoxGrid,
  Picture,
  RemoveButton,
  Upload,
};

type Props = Readonly<typeof defaultProps> & ChildrenProps;

type Children = (
  props: Props &
    ChildrenProps &
    Readonly<{
      timelineGapMetrics: TimelineGapMetrics;
      onRemove: (index: number) => void;
      getDurationBetween: (index: number) => string;
      updateSwap: (event: Sortable.SortableEvent) => void;
    }>,
) => JSX.Element;

export const SortableList = ({
  children,
  listChildren,
  LoadingList,
  LoadingGrid,
  ...props
}: {
  children: Children;
  listChildren: Children;
  LoadingList: () => JSX.Element;
  LoadingGrid: () => JSX.Element;
} & Readonly<typeof defaultProps>) => {
  const {
    project,
    timeline,
    updateTimeline,
    assets,
    sectionsCount,
    activeIndex,
    addPictures,
  } = useTimelineContext();
  const { isLoadingAudio } = usePlayerContext();
  const [isListView, setIsListView] = useState(isTouchDevice);
  const { getDurationBetween } = useGetDurationBetweenContext();
  const { submittedAt } = useTimelineContext();

  const updateSwap = (event: Sortable.SortableEvent) => {
    const items = Array.from(timeline);

    if (event.oldIndex !== undefined && event.newIndex !== undefined) {
      //swap items at index
      items[event.newIndex] = timeline[event.oldIndex];
      items[event.oldIndex] = timeline[event.newIndex];

      updateTimeline(items);
    }
  };

  const onRemove = (index: number) => {
    updateTimeline(
      (timeline) =>
        timeline
          .map((item, i) => {
            if (i !== index) {
              return item;
            }

            if (index > sectionsCount - 1) {
              return undefined;
            }

            return { id: v4() };
          })
          .filter((item) => item !== undefined) as TimelineListType,
    );
  };

  const timelineGapMetrics = useExtendedTimelineMetrics();

  return (
    <>
      <ValidityDisplay />
      <HeaderWrapper>
        <ViewSwitcher isListView={isListView} setIsListView={setIsListView} />
      </HeaderWrapper>

      {isLoadingAudio ? (
        isListView ? (
          <LoadingList />
        ) : (
          <LoadingGrid />
        )
      ) : isListView ? (
        listChildren({
          ...props,
          projectId: project.id,
          list: timeline,
          updateTimeline,
          sectionsCount,
          activeIndex,
          assets,
          addPictures,
          timelineGapMetrics,
          updateSwap,
          getDurationBetween,
          onRemove,
          submittedAt,
        })
      ) : (
        children({
          ...props,
          projectId: project.id,
          list: timeline,
          updateTimeline,
          sectionsCount,
          activeIndex,
          assets,
          addPictures,
          timelineGapMetrics,
          getDurationBetween,
          updateSwap,
          onRemove,
          submittedAt,
        })
      )}
    </>
  );
};

const loadingList = new Array(12).fill(null);
SortableList.defaultProps = {
  ...defaultProps,
  listChildren: ({
    projectId,
    list,
    timelineGapMetrics,
    sectionsCount,
    activeIndex,
    addPictures,
    onRemove,
    submittedAt,
    getDurationBetween,
    updateSwap,
    Title,
    Picture,
  }: Omit<Props, "isLoading"> & {
    timelineGapMetrics: TimelineGapMetrics;
    onRemove: (index: number) => void;
    getDurationBetween: (index: number) => string;
    updateSwap: (event: Sortable.SortableEvent) => void;
  }) => {
    return (
      <ReactSortable
        tag="ul"
        group="Timeline--list"
        list={list}
        setList={() => {}}
        onSort={(ev) => updateSwap(ev)}
        handle=".drag-handle"
        swap
        swapThreshold={1}
        dragoverBubble={true}
        animation={150}
        className="grid--list"
        delay={isTouchDevice ? 50 : 0}
        scrollSpeed={20}
      >
        {list.map((item, index) => {
          const isOutstanding = index > sectionsCount - 1;
          const isActive = activeIndex === index;
          const hasPicture = item.assetId;
          const metrics = timelineGapMetrics.listMetrics[index];

          return (
            <TimelineBoxList
              id={item.id}
              key={item.id}
              isActive={isActive}
              isOutstanding={isOutstanding}
            >
              {hasPicture ? (
                <ListButton
                  className="icon--remove"
                  onClick={() => onRemove(index)}
                >
                  <RemoveIcon />
                </ListButton>
              ) : (
                <ListButton
                  onClick={() => {
                    handleUploadClick({
                      storyId: projectId,
                      callback: (error, result) => {
                        addPictures(error, result, index);
                      },
                    });
                  }}
                >
                  <UploadIcon />
                </ListButton>
              )}

              <TimelineBoxBorder
                isActive={isActive}
                isOutstanding={isOutstanding}
              >
                <PictureWrapper>
                  {hasPicture ? (
                    <Picture index={index} item={item} width={600} height={400} />
                  ) : (
                    <EmptyPicture
                      isGap={metrics === ItemMetric.gap}
                      isLooped={
                        timelineGapMetrics.isValid &&
                        metrics === ItemMetric.empty
                      }
                      isInvalid={
                        !timelineGapMetrics.isValid &&
                        [ItemMetric.empty, ItemMetric.gap].includes(metrics) &&
                        Boolean(submittedAt)
                      }
                    />
                  )}
                </PictureWrapper>

                <Title isActive={isActive}>{getDurationBetween(index)}</Title>

                <DragHandle className="drag-handle" isActive={isActive} />
              </TimelineBoxBorder>
            </TimelineBoxList>
          );
        })}
      </ReactSortable>
    );
  },
  children: ({
    projectId,
    list,
    timelineGapMetrics,
    sectionsCount,
    activeIndex,
    addPictures,
    onRemove,
    getDurationBetween,
    updateSwap,
    Title,
    TimelineBox,
    Picture,
    RemoveButton,
    Upload,
  }: Omit<Props, "isLoading"> & {
    timelineGapMetrics: TimelineGapMetrics;
    onRemove: (index: number) => void;
    getDurationBetween: (index: number) => string;
    updateSwap: (event: Sortable.SortableEvent) => void;
  }) => {
    return (
      <ReactSortable
        tag="ul"
        group="Timeline"
        list={list}
        setList={() => {}}
        onSort={(ev) => updateSwap(ev)}
        swap
        swapThreshold={1}
        dragoverBubble={true}
        animation={150}
        className="grid"
        delay={isTouchDevice ? 100 : 0}
        scrollSpeed={20}
      >
        {list.map((item, index) => {
          const isOutstanding = index > sectionsCount - 1;
          const isActive = activeIndex === index;
          const hasPicture = item.assetId;
          const metrics = timelineGapMetrics.listMetrics[index];

          return (
            <TimelineBox
              id={item.id}
              key={item.id}
              isActive={isActive}
              isOutstanding={isOutstanding}
            >
              <Title isActive={isActive}>{getDurationBetween(index)}</Title>

              {hasPicture ? (
                <Picture index={index} item={item} width={600} height={400} />
              ) : (
                <Upload
                  index={index}
                  isActive={isActive}
                  metrics={metrics}
                  onUpload={() =>
                    handleUploadClick({
                      storyId: projectId,
                      callback: (error, result) => {
                        addPictures(error, result, index);
                      },
                    })
                  }
                />
              )}
              {(item.assetId || isOutstanding) && (
                <RemoveButton onClick={() => onRemove(index)}>
                  Remove
                </RemoveButton>
              )}
            </TimelineBox>
          );
        })}
      </ReactSortable>
    );
  },
  LoadingGrid: () => (
    <ul className="grid">
      {loadingList.map((_, index) => (
        <TimelineBoxGrid key={index}>
          <Title />
          <Skeleton height={182} />
        </TimelineBoxGrid>
      ))}
    </ul>
  ),
  LoadingList: () => (
    <ul className="grid--list">
      {loadingList.map((_, index) => (
        <TimelineBoxGrid key={index}>
          <Title />
          <Skeleton height={64} />
        </TimelineBoxGrid>
      ))}
    </ul>
  ),
};

const ListButton = styled(GhostButton)`
  width: 4rem;
`;
const UploadIcon = () => (
  <FontAwesomeIcon icon={faFileUpload} color={"#000000"} />
);
const RemoveIcon = () => <FontAwesomeIcon icon={faTrash} color={"#f64d1c"} />;

const DragIcon = () => <FontAwesomeIcon icon={faBars} />;
const DragHandle = styled((props: Record<string, unknown>) => (
  <div {...props}>
    <DragIcon />
  </div>
))<{ className?: string; isActive: boolean }>`
  width: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #dee4f2;

  ${({ isActive }) =>
    isActive &&
    css`
      color: #000000;
    `}

  .sortable-chosen & {
    color: #f64d1c;
  }

  .sortable-swap-highlight & {
    color: #000000;
  }
`;

const PictureWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 6rem;
  overflow: hidden;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;
  row-gap: 1rem;
  color: #000000;

  padding-top: 1rem;

  @media screen and (min-width: ${rem(768)}) {
    padding-top: 2rem;
  }
`;
